import * as React from 'react'
import { Button, Form, Header, Icon } from 'semantic-ui-react'
import InputField from './InputField'
import { checkEmail } from './../utils/validators'

export enum AuthFormViewEnum {
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
}

export interface IAuthFormProps {
  onSubmit: (email: string, password: string) => void
  view: AuthFormViewEnum
  isAuthorizing: boolean
}

export interface IAuthFormState {
  email: string
  password: string
  passwordRepeat: string
  isEmailValid: boolean
  isPasswordValid: boolean
  isPasswordRepeatValid: boolean
  emailTouched: boolean
  passwordTouched: boolean
  passwordRepeatTouched: boolean
}

type EventProps = {
  value: string
}

class AuthForm extends React.Component<IAuthFormProps, IAuthFormState> {
  state: IAuthFormState = {
    email: '',
    password: '',
    passwordRepeat: '',
    isEmailValid: false,
    isPasswordValid: false,
    isPasswordRepeatValid: false,
    emailTouched: false,
    passwordTouched: false,
    passwordRepeatTouched: false,
  }

  componentWillMount() {
    if (process.env.NODE_ENV === 'development') {
      this.setState({
        email: 'admin@domain.dev',
        password: 'admin',
        isEmailValid: true,
        isPasswordValid: true,
      })
    }
  }

  handleSubmit = (): void => {
    const { isEmailValid, isPasswordValid, isPasswordRepeatValid, email, password } = this.state
    if (
      this.props.view === AuthFormViewEnum.REGISTRATION &&
      (!isPasswordValid || !isPasswordRepeatValid)
    ) {
      return
    }

    if (isEmailValid) {
      this.props.onSubmit(email.trim(), password.trim())
    }
  }

  handleEmailChange = (_e: React.SyntheticEvent<HTMLInputElement>, { value }: EventProps): void => {
    this.validateEmail(value)
    this.setState({ email: value })
  }

  handlePasswordChange = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: EventProps
  ): void => {
    this.setState({ password: value })
  }

  validateEmail = (value: string): void => {
    this.setState({ isEmailValid: checkEmail(value) })
  }

  validatePassword = (_e: React.SyntheticEvent<HTMLInputElement> | null, { value }: EventProps) => {
    this.setState({ isPasswordValid: value.length > 5, password: value }, () => {
      this.validatePasswordRepeat(null, { value: this.state.passwordRepeat })
    })
  }

  validatePasswordRepeat = (
    _e: React.SyntheticEvent<HTMLInputElement> | null,
    { value }: EventProps
  ) => {
    this.setState(prevState => ({
      passwordRepeat: value,
      isPasswordRepeatValid: prevState.password === value,
    }))
  }

  render() {
    const { view, isAuthorizing } = this.props
    const {
      isEmailValid,
      isPasswordValid,
      isPasswordRepeatValid,
      emailTouched,
      passwordTouched,
      passwordRepeatTouched,
    } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        {view === AuthFormViewEnum.LOGIN ? (
          <Header textAlign="center">
            Авторизация <Icon name="sign in" size="small" />
          </Header>
        ) : (
          <Header textAlign="center">
            Регистрация <Icon name="signup" size="small" />
          </Header>
        )}
        <InputField
          name="E-mail"
          required={process.env.NODE_ENV === 'production'}
          label="E-mail"
          value={this.state.email}
          placeholder="username@mail.com"
          onChange={this.handleEmailChange}
          onBlur={() => {
            this.setState({ emailTouched: true })
          }}
          isValid={isEmailValid}
          touched={emailTouched}
          error="Неправильный адрес почты"
        />
        {view === AuthFormViewEnum.LOGIN ? (
          <Form.Input
            name="password"
            type="password"
            required={process.env.NODE_ENV === 'production'}
            label="Пароль"
            value={this.state.password}
            placeholder="Ваш пароль"
            onChange={this.handlePasswordChange}
          />
        ) : (
          <>
            <InputField
              name="password"
              type="password"
              required
              label="Пароль"
              placeholder="Ваш пароль"
              onChange={this.validatePassword}
              onBlur={() => {
                this.setState({ passwordTouched: true })
              }}
              isValid={isPasswordValid}
              touched={passwordTouched}
              error="Пароль должен содержать минимум 6 символов"
            />
            <InputField
              name="password-check"
              type="password"
              required
              label="Подтверждение пароля"
              placeholder="Ваш пароль"
              onChange={this.validatePasswordRepeat}
              onBlur={() => {
                this.setState({ passwordRepeatTouched: true })
              }}
              isValid={isPasswordRepeatValid}
              touched={passwordRepeatTouched}
              error="Должно совпадать с полем 'Пароль'"
            />
          </>
        )}
        <Button
          fluid
          positive
          content={view === AuthFormViewEnum.LOGIN ? 'Войти' : 'Зарегистрироваться'}
          loading={isAuthorizing}
        />
      </Form>
    )
  }
}

export default AuthForm
