import { CustomError } from 'ts-custom-error';

type RequestId = string | null;

export class ApiError extends CustomError {
  public code: number;
  public payload?: any;
  public requestId: RequestId;

  public constructor(code: number, message: string, payload?: any, requestId: RequestId = null) {
    super(message);

    this.code = code;
    this.payload = payload;
    this.requestId = requestId;
  }
}

export class RequestError extends CustomError {
  public code: number;
  public response?: Response;
  public requestId: RequestId;

  public constructor(
    code: number,
    message: string,
    response?: Response,
    requestId: RequestId = null
  ) {
    super(message);

    this.code = code;
    this.response = response;
    this.requestId = requestId;
  }
}
