import { NormalizedSchema, Schema } from 'normalizr';

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type TokenOption = string | (() => Promise<string>);

export interface CallApiOptions {
  headers?: Record<string, string>;
  token?: TokenOption;
  data?: any;
  schema?: Schema;
  respSchema?: Schema;
}

export interface ApiErrorItem {
  property: string;
  message: string;
  code: string;
  params?: {
    [key: string]: any;
  };
}

interface ResponsePayloadMeta {
  meta?: Record<string, any>;
}

export interface ResponsePayload extends ResponsePayloadMeta {
  data: any;
}

export interface CallApiResult {
  response: Response;
  payload?: ResponsePayload | (NormalizedSchema<any, any> & ResponsePayloadMeta) | null;
}
