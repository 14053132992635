import React from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { Container, Grid, Message } from 'semantic-ui-react'
import { ApiError } from '@airmedia/api-utils'
import AuthForm, { AuthFormViewEnum } from './AuthForm'
import { isAuthError, signIn, signUp } from '../api'

const enum RegisteredViewEnum {
  REGISTERED = 'REGISTERED',
}

export interface IAuthPageProps {
  modal?: boolean
  location: {
    state: any
  }
  onSubmit: () => void
}

export interface IAuthPageState {
  view: AuthFormViewEnum | RegisteredViewEnum
  isAuthorizing: boolean
  error: any
  redirectToReferrer: boolean
}

interface IAuthPageWrapProps {
  readonly modal: boolean
}

const AuthPageWrap = styled.div<IAuthPageWrapProps>`
  padding-top: ${props => (props.modal ? 'unset' : 'calc(50vh - 200px)')};
  border-top: 30px solid transparent;

  .error-message {
    white-space: pre-line;
  }

  .link {
    display: inline-block;
    margin-top: 10px;
    color: rgba(65, 137, 196, 1);
    text-decoration: underline;
    border: unset;
    outline: unset;
    background: unset;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-shadow: 0 0 2px rgba(65, 137, 196, 0.4);
    }

    &:active {
      opacity: 0.7;
    }
  }
`

const getErrorText = (error: any): string => {
  if (isAuthError(error)) {
    return 'Неверный логин или пароль.'
  }

  if (error instanceof ApiError && error.code >= 500) {
    return 'Сервис авторизации временно недоступен.'
  }

  return 'Что-то пошло не так.'
}

class AuthPage extends React.Component<IAuthPageProps, IAuthPageState> {
  static defaultProps = {
    onSubmit: null,
    modal: null,
  }

  state: IAuthPageState = {
    view: AuthFormViewEnum.LOGIN,
    isAuthorizing: false,
    error: null,
    redirectToReferrer: false,
  }

  handleSubmit = async (email: string, password: string) => {
    this.setState({ isAuthorizing: true })

    try {
      if (this.state.view === AuthFormViewEnum.LOGIN) {
        await signIn(email, password)
      } else {
        await signUp(email, password)
      }
    } catch (e) {
      this.setState({
        isAuthorizing: false,
        error: e,
      })

      return
    }

    this.setState(
      prevState => ({
        isAuthorizing: false,
        error: null,
        view:
          prevState.view === AuthFormViewEnum.REGISTRATION
            ? RegisteredViewEnum.REGISTERED
            : prevState.view,
        redirectToReferrer: prevState.view === AuthFormViewEnum.LOGIN,
      }),
      () => {
        if (this.props.onSubmit) {
          this.props.onSubmit()
        }
      }
    )
  }

  handleLoginClick = (): void => {
    this.setState({ view: AuthFormViewEnum.LOGIN })
  }

  handleToggleForm = () => {
    this.setState(prevState => ({
      view:
        prevState.view === AuthFormViewEnum.LOGIN
          ? AuthFormViewEnum.REGISTRATION
          : AuthFormViewEnum.LOGIN,
    }))
  }

  renderBtn() {
    const { view } = this.state

    return (
      <button type="button" className="link" onClick={this.handleToggleForm}>
        {view === AuthFormViewEnum.LOGIN ? 'Зарегистрироваться' : 'Войти'}
      </button>
    )
  }

  renderError() {
    const { error } = this.state

    if (error) {
      return <Message className="error-message" negative content={getErrorText(error)} />
    }

    return null
  }

  render() {
    const { view, isAuthorizing, redirectToReferrer } = this.state

    if (redirectToReferrer) {
      const { from } = this.props.location.state || { from: { pathname: '/' } }
      return <Redirect to={from} />
    }

    return (
      <AuthPageWrap modal={!!this.props.modal}>
        <Container>
          <Grid columns={1} centered>
            <Grid.Row>
              <Grid.Column width={this.props.modal ? 16 : 6}>
                {view === RegisteredViewEnum.REGISTERED ? (
                  <>
                    <Message info>
                      <Message.Header>Регистрация успешно завершена</Message.Header>
                      <p>
                        Вы сможете {/* eslint-disable */}
                        <a className="link" onClick={this.handleLoginClick}>
                          войти
                        </a>{' '}
                        {/* eslint-enable */}в систему после активации аккаунта.
                      </p>
                    </Message>
                  </>
                ) : (
                  <>
                    <AuthForm
                      onSubmit={this.handleSubmit}
                      view={view}
                      isAuthorizing={isAuthorizing}
                    />
                    {this.renderBtn()}
                    {this.renderError()}
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </AuthPageWrap>
    )
  }
}

export default AuthPage
