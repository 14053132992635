import { callApi, RequestMethod, CallApiOptions } from '@airmedia/api-utils'

const BASE_URL =
  process.env.REACT_APP_USER_API ||
  (process.env.NODE_ENV === 'production' ? 'https://api.users.airmediapro.ru' : '')

export default (method: RequestMethod, endpoint: string, options?: CallApiOptions) => {
  const fullUrl =
    BASE_URL && endpoint.indexOf(BASE_URL) === -1 ? `${BASE_URL}${endpoint}` : endpoint
  return callApi(method, fullUrl, options)
}
