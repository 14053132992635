import { normalize } from 'normalizr';
import { ApiError, RequestError } from './errors';
import {
  CallApiOptions,
  CallApiResult,
  RequestMethod,
  ResponsePayload,
  TokenOption,
} from './types';

const HEADER_CONTENT_TYPE = 'Content-Type';
const HEADER_AUTHORIZATION = 'Authorization';
const HEADER_REQUEST_ID = 'X-Request-ID';

function resolveToken(token: TokenOption): Promise<string> {
  return new Promise<string>(resolve => {
    if (typeof token === 'function') {
      resolve(token());
    } else {
      resolve(token);
    }
  });
}

const isJson = (resp: Response): boolean =>
  (resp.headers.get(HEADER_CONTENT_TYPE) || '').split(';')[0] === 'application/json';

const buildFetchOptions = async (
  method: RequestMethod,
  options?: CallApiOptions
): Promise<RequestInit> => {
  const headers = new Headers({
    Accept: 'application/json',
  });

  const fetchOptions: RequestInit = {
    method,
    headers,
    credentials: 'same-origin',
  };

  if (options) {
    if (options.headers !== undefined) {
      for (const key in options.headers) {
        if (!options.headers.hasOwnProperty(key)) {
          continue;
        }

        headers.set(key, options.headers[key]);
      }
    }

    if (options.token) {
      const token = await resolveToken(options.token);
      headers.set(HEADER_AUTHORIZATION, `Bearer ${token}`);
    }

    if (options.data) {
      headers.set(HEADER_CONTENT_TYPE, 'application/json');
      fetchOptions.body = JSON.stringify({ data: options.data });
    }
  }

  return fetchOptions;
};

const handleResponse = (
  resp: Response,
  payload: ResponsePayload | null,
  options?: CallApiOptions
): CallApiResult => {
  if (options && payload) {
    if (options.schema) {
      return {
        payload: {
          ...normalize(payload.data, options.schema),
          meta: payload.meta,
        },
        response: resp,
      };
    }

    if (options.respSchema) {
      return {
        payload: normalize(payload, options.respSchema),
        response: resp,
      };
    }
  }

  return {
    payload,
    response: resp,
  };
};

export default async function callApi(
  method: RequestMethod,
  url: string,
  options?: CallApiOptions
): Promise<CallApiResult> {
  const fetchOptions = await buildFetchOptions(method, options);
  const resp = await fetch(url, fetchOptions);

  if (isJson(resp)) {
    const payload = await resp.json();
    if (resp.ok) {
      return handleResponse(resp, payload, options);
    }
    if (resp.status >= 400) {
      throw new ApiError(
        resp.status,
        payload.message || 'API error',
        payload,
        resp.headers.get(HEADER_REQUEST_ID)
      );
    }
  }

  if (resp.status === 201 || resp.status === 202 || resp.status === 204) {
    return {
      payload: null,
      response: resp,
    };
  }

  throw new RequestError(
    resp.status,
    `API request failed - ${resp.statusText}`,
    resp,
    resp.headers.get(HEADER_REQUEST_ID)
  );
}
