import { CustomError } from 'ts-custom-error'

export class AuthError extends CustomError {
  public constructor(message?: string) {
    super(message || 'Authorization failed')
  }
}

export class TokenError extends CustomError {
  public constructor(message?: string) {
    super(message || 'Invalid access token')
  }
}
