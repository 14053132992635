import React from 'react'
import { Form, FormInputProps } from 'semantic-ui-react'
import ErrorMessage from './ErrorMessage'

interface Props extends FormInputProps {
  error: string
  isValid: boolean
  touched: boolean
}

const InputField = ({ isValid, error, touched, ...rest }: Props): React.ReactElement => (
  <>
    <Form.Input {...rest} />
    {touched && !isValid && <ErrorMessage error={error} />}
  </>
)

export default InputField
