import React from 'react'
import styled from 'styled-components'

interface IErrorMessageProps {
  error: string
}

const ErrorMessageWrap = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #db2828;
  transform: translateY(-10px);
`

const ErrorMessage = ({ error }: IErrorMessageProps): React.ReactElement => (
  <ErrorMessageWrap>{error}</ErrorMessageWrap>
)

export default ErrorMessage
