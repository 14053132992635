const ACCESS_TOKEN_KEY = 'auth/id_token'
const REFRESH_TOKEN_KEY = 'auth/refresh_token'

export const updateToken = (accessToken: string, refreshToken: string): void => {
  if (!accessToken) {
    throw new Error('Access token cannot be empty')
  }
  if (!refreshToken) {
    throw new Error('Refresh token cannot be empty')
  }

  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
}

export const removeToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export const getAccessToken = (): string | null => localStorage.getItem(ACCESS_TOKEN_KEY)
export const getRefreshToken = (): string | null => localStorage.getItem(REFRESH_TOKEN_KEY)
