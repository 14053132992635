import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatter from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { DateValue } from 'types';

const options = {
  locale: ru,
};

const distanceOptions = {
  addSuffix: true,
  ...options,
};

export const toDate = (date: DateValue): Date => {
  if (date.constructor === Date) {
    return date as Date;
  }

  if (typeof date === 'string') {
    const timestamp = Date.parse(date);
    if (Number.isNaN(timestamp)) {
      throw new RangeError(`Invalid date: ${date}`);
    }
    return new Date(timestamp);
  }

  return new Date(date);
};

export const formatDate = (date: DateValue, format: string) =>
  formatter(toDate(date), format, options);

export const formatDistance = (date: DateValue) =>
  formatDistanceToNow(toDate(date), distanceOptions);

export const utcDate = (date: DateValue) => {
  const utcDate = date instanceof Date ? date.toISOString() : new Date(date).toISOString();
  return utcDate.replace(/\.\d+/, '');
};
