import { camelizeKeys } from 'humps'
import jwtDecode from 'jwt-decode'
import { ApiError, CallApiResult, RequestMethod, ResponsePayload } from '@airmedia/api-utils'
import { ProfileResponse, SignInResponse } from './types'
import callApi from './callApi'
import { TokenError } from './errors'
import { getAccessToken, getRefreshToken, removeToken, updateToken } from '../token'

export * from './errors'
export * from './types'

type jwt = {
  exp: number
}

type RefreshTokenResponse = {
  accessToken: string
  refreshToken: string
}

const isExpired = (token: string): boolean => {
  const decoded: jwt = jwtDecode(token)
  return !!decoded.exp && decoded.exp < Date.now() / 1000
}

export const isAuthError = (err: any): boolean => err instanceof ApiError && err.code === 401

const refreshAccessToken = (): Promise<string> => {
  const token = getRefreshToken()

  if (!token) {
    return Promise.reject(new TokenError('Refresh token not found'))
  }

  const payload = {
    refresh_token: token,
  }

  return callApi(RequestMethod.POST, '/api/auth/token', { data: payload })
    .then((res: CallApiResult) => {
      if (!res.payload) {
        throw Error('Unknown response')
      }

      const payload = res.payload as ResponsePayload
      const { accessToken, refreshToken } = (camelizeKeys(
        payload.data
      ) as unknown) as RefreshTokenResponse

      updateToken(accessToken, refreshToken)

      return accessToken
    })
    .catch(err => {
      if (isAuthError(err)) {
        removeToken()
      }
      throw err
    })
}

const fetchProfileInternal = (accessToken: string): Promise<ProfileResponse> =>
  callApi(RequestMethod.GET, '/api/me', { token: accessToken }).then((res: CallApiResult) => {
    if (!res.payload) {
      throw Error('Unknown response')
    }

    const payload = res.payload as ResponsePayload

    return (camelizeKeys(payload.data) as unknown) as ProfileResponse
  })

export const isAuth = (): boolean => !!getAccessToken()

export const signOut = (): void => removeToken()

export const signIn = (email: string, password: string): Promise<SignInResponse> =>
  callApi(RequestMethod.POST, '/api/auth/login', { data: { email, password } }).then(
    (res: CallApiResult) => {
      if (!res.payload) {
        throw Error('Unknown response')
      }

      const payload = res.payload as ResponsePayload
      const signInResponse = (camelizeKeys(payload.data) as unknown) as SignInResponse

      updateToken(signInResponse.accessToken, signInResponse.refreshToken)

      return signInResponse
    }
  )

export const signUp = (email: string, password: string) =>
  callApi(RequestMethod.POST, '/api/auth/register', { data: { email, password } })

let refreshPending: Promise<string> | null

export const fetchAccessToken = (refresh: boolean = false): Promise<string> =>
  new Promise((resolve, reject) => {
    const token = getAccessToken()
    if (!token) {
      reject(new TokenError('Access token not found'))
    } else if (refreshPending) {
      refreshPending.then(resolve, reject)
    } else if (refresh || isExpired(token)) {
      refreshPending = refreshAccessToken()
      refreshPending
        .then(accessToken => {
          resolve(accessToken)
          refreshPending = null
        })
        .catch(err => {
          reject(err)
          refreshPending = null
        })
    } else {
      resolve(token)
    }
  })

export const fetchProfile = (): Promise<ProfileResponse> =>
  fetchAccessToken()
    .then(fetchProfileInternal)
    .catch(err => {
      if (isAuthError(err)) {
        return fetchAccessToken(true).then(fetchProfileInternal)
      }
      throw err
    })
