import * as React from 'react';
import { formatDate, formatDistance, utcDate, toDate } from '../utils';
import { ITimeProps } from './types';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const getUpdateDelay = (timeDiff: number): number | null => {
  if (timeDiff < MINUTE) {
    return 1000;
  }
  if (timeDiff < HOUR) {
    return MINUTE * 1000;
  }
  if (timeDiff < DAY) {
    return HOUR * 1000;
  }
  return null;
};

const FORMAT_DEFAULT = 'dd.MM.yyyy HH:mm:ss';

export default class Time extends React.Component<ITimeProps> {
  private timeoutId: number | null = null;

  public componentDidMount() {
    if (this.props.relative) {
      this.tick(true);
    }
  }

  public componentDidUpdate(prevProps: ITimeProps) {
    if (prevProps.date !== this.props.date || prevProps.relative !== this.props.relative) {
      this.resetTimer();
      if (this.props.relative) {
        this.tick(true);
      }
    }
  }

  public componentWillUnmount() {
    this.resetTimer();
  }

  public tick = (preventUpdate: boolean) => {
    const { date } = this.props;
    const timeDiff = Math.round(Math.abs(Date.now() - toDate(date).getTime()) / 1000);
    const delay = getUpdateDelay(timeDiff);

    if (delay) {
      this.timeoutId = setTimeout(this.tick, delay);
    }

    if (preventUpdate) {
      return;
    }

    this.forceUpdate();
  };

  private format(date: Date) {
    if (this.props.relative) {
      return formatDistance(date);
    }
    return formatDate(date, this.props.format || FORMAT_DEFAULT);
  }

  private resetTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  public render() {
    const { date, titleFormat } = this.props;
    const dateObject = toDate(date);
    const machineReadable = utcDate(dateObject);

    return (
      <time
        title={formatDate(dateObject, titleFormat || FORMAT_DEFAULT)}
        dateTime={machineReadable}
      >
        {this.format(dateObject)}
      </time>
    );
  }
}
